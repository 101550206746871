import React, { useContext, useEffect, useState } from "react";

// Context
import { DataContext } from "context/DataContext";

// Router
import { Link } from "react-router-dom";

export const Step01 = () => {
  const { formData, setFormData, setStep, step, data } =
    useContext(DataContext);
  const [name, setName] = useState("");

  useEffect(() => {
    if (formData?.name !== "") {
      setName(formData?.name);
    }
  }, []);

  // Handlers
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({ ...formData, name });
    window.analytics.identify({"name": name});
    setStep(step + 1);
  };

  return (
    <form className="w-full px-4 text-secondary" onSubmit={handleSubmit}>
      <div>
        <p className="text-4xl lg:text-5xl font-semibold font-gilroyBold">
          Hey there!
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          <span className="font-proximaNovaBold">We are Authentic.</span> What's
          your name?
        </p>
      </div>
      <div className="mt-[40px]">
        <input
          type="text"
          id="name"
          className="h-[70px] bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
          required
          placeholder="What is your first and last name?"
          value={name}
          onChange={handleNameChange}
        />
      </div>

      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
        onClick={() => window.analytics.track("Button Clicked", {"button_location": "onboarding_form", "intent": "onboarding", "title": "Next: Community Type"})}
      >
        <strong className="font-proximaNovaBold">Next:</strong> Community Type
      </button>
    </form>
  );
};
