export const boxVariant = {
  visible: { y: "40px" },
  hidden: { y: 0 },
};

export const circleVariant = {
  visible: { y: "160px", rotate: 360 },
  hidden: { y: 0, rotate: 0 },
};

export const textVariant = {
  visible: { x: "-500px" },
  hidden: { x: "500px" },
};
