import React, { useContext } from "react";

// Context
import { ModeContext } from "context";

// React Scroll
import { scroller } from "react-scroll";

// Theme
import { Heading1 } from "theme";

// Framer Motion
import { motion } from "framer-motion";

export const HeroSection = () => {
  const { darkMode, setDarkMode } = useContext(ModeContext);

  return (
    <section
      id="hero"
      className="px-[24px] -mt-[108px] pt-[108px] md:px-[48px] flex flex-col min-h-[600px] lg:min-h-[822px] w-full justify-evenly items-center relative mx-auto max-w-[1280px]"
    >
      {/* Theme  */}
      <div className="flex z-20 justify-start items-start text-left w-full mt-4 absolute top-[108px] left-0 px-[24px] lg:px-[48px] xl:px-0">
        <span
          onClick={() => setDarkMode(true)}
          className={`${
            darkMode ? "!font-proximaNovaBold" : "!font-proximaNovaMedium"
          } uppercase cursor-pointer text-secondary dark:text-off-white hover:text-primary tracking-[28%] text-[12px] `}
        >
          Dark
        </span>{" "}
        <span className="mx-2 w-[1px] h-[20px] bg-secondary dark:bg-off-white"></span>
        <span
          onClick={() => setDarkMode(false)}
          className={`${
            darkMode ? "!font-proximaNovaMedium" : "!font-proximaNovaBold"
          } uppercase cursor-pointer text-secondary dark:text-off-white hover:text-primary tracking-[28%] text-[12px] `}
        >
          Light
        </span>
      </div>

      {/* Content */}
      <Heading1
        text={
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            Hello, we are <span className="text-primary">Authentic</span>
          </motion.span>
        }
        className="text-secondary z-20 dark:text-off-white max-w-2xl lg:max-w-5xl"
      />
      <div className="text-7xl lg:text-8xl xl:text-9xl max-w-xl xl:max-w-5xl"></div>
      <div className="flex flex-col justify-center items-center absolute bottom-0">
        <div className="uppercase animate-bounce duration-300 z-20 tracking-[0.1em] font-proximaNovaMedium text-xs ">
          Scroll
        </div>
        <svg
          onClick={() => scroller.scrollTo("belief")}
          className="w-4 z-20 h-10 mt-4 stroke-secondary dark:stroke-off-white"
          viewBox="0 0 28 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 0V72M14 72L27 58.7647M14 72L1 58.7647" />
        </svg>
      </div>
    </section>
  );
};
