import React, { useRef, useEffect, useContext } from "react";

// Context
import { DataContext } from "context/DataContext";

// Components
import {
  Sidebar,
  Step01,
  Step02,
  Step03,
  Step04,
  Step05,
  Step06,
} from "../components";

// Router
import { useNavigate } from "react-router-dom";

export const StepForm = () => {
  const { step } = useContext(DataContext);
  const navigate = useNavigate();

  let form = <div></div>;
  
  let locationRef = useRef();
  useEffect(() => {
    let location = window.location;
    if(locationRef.current === location.pathname){
        return;
    }
    window.analytics.page(location.pathname);
    locationRef.current = location.pathname;
  });



  // Stepper
  if (step === 1) {
    form = <Step01 />;
  } else if (step === 2) {
    form = <Step02 />;
  } else if (step === 3) {
    form = <Step03 />;
  } else if (step === 4) {
    form = <Step04 />;
  } else if (step === 5) {
    form = <Step05 />;
  } else if (step === 6) {
    form = <Step06 />;
  } else {
    navigate("/");
  }

  return (
    <div className="mx-auto w-full min-h-screen flex bg-off-white">
      <Sidebar />

      <div className="max-w-xl md:max-w-[700px] lg:max-w-[800px] mx-auto w-full min-h-screen font-proximaNovaRegular flex flex-col justify-center items-center text-secondary md:w-2/3">
        {form}
      </div>
    </div>
  );
};
