import { useEffect, useRef } from "react";

// Router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Components
import { Root, StepForm, ThankYou, Verified } from "routes";

// Context
import { AuthProvider } from "context/AuthContext";

// Parallax Provider
import { ParallaxProvider } from "react-scroll-parallax";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ParallaxProvider>
        <AuthProvider>
          <Root />
        </AuthProvider>
      </ParallaxProvider>
    ),
  },
  {
    path: "/onboarding",
    element: (
      <AuthProvider>
        <StepForm />
      </AuthProvider>
    ),
  },
  {
    path: "/verified",
    element: (
      <AuthProvider>
        <Verified />
      </AuthProvider>
    ),
  },
  {
    path: "/thank-you",
    element: (
      <AuthProvider>
        <ThankYou />
      </AuthProvider>
    ),
  },
]);

function App() {

  return <RouterProvider router={router} />;
}

export default App;
