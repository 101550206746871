import React, { useContext } from "react";

// Context
import { DataContext } from "context/DataContext";

export const Footer = () => {
  const { data } = useContext(DataContext);

  return (
    <footer className="h-[250px] z-20 px-4 sm:px-6 bg-secondary dark:bg-off-white text-off-white dark:text-secondary">
      <div className="flex flex-col items-center justify-center h-full">
        {data?.logoText && (
          <p className="font-gilroyExtraBold ml-4 w-32 mb-4 text-3xl text-primary">
            {data?.logoText}
          </p>
        )}

        <span className="font-proximaNovaRegular text-sm  sm:text-center ">
          {data?.footerText}
        </span>
      </div>
    </footer>
  );
};
