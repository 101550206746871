import { useState, createContext } from "react";
import auth0 from "auth0-js";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [user, setUser] = useState({});
  const [verificationCodeError, setVerificationCodeError] = useState({});

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const startAuth = ({ userEmail }) => {
    let webAuth = new auth0.WebAuth({
      domain: domain,
      clientID: clientId,
      responseType: "token id_token",
      redirectUri: window.location.origin + "/verified",
    });
    setEmail(userEmail);
    webAuth.passwordlessStart(
      {
        connection: "email",
        send: "code",
        email: userEmail,
      },
      function (err, res) {
      }
    );
  };

  const verifyAuth = ({ code, data }) => {
    let encodedData = btoa(JSON.stringify(data));
    let redirectUri = window.location.origin + "/verified";
    let webAuth = new auth0.WebAuth({
      domain: domain,
      clientID: clientId,
      responseType: "token id_token",
      redirectUri: redirectUri,
    });

    webAuth.passwordlessLogin(
      {
        connection: "email",
        email: email,
        verificationCode: code,
      },
      function (err, res) {
        if (err) setVerificationCodeError(err);
      }
    );
  };

  const completeAuth = () => {
        let webAuth = new auth0.WebAuth({
          domain: domain,
          clientID: clientId,
          responseType: "token id_token",
          redirectUri: window.location.origin + "/verified",
        });

        if(window.location.hash) {
            webAuth.parseHash(
              { hash: window.location.hash },
              function (err, authResult) {
                webAuth.client.userInfo(authResult?.accessToken, function (err, user) {
                  setAccessToken(authResult?.accessToken);
                  setUser(user);
                });
              }
            );
        }
  };

  return (
    <AuthContext.Provider
      value={{ startAuth, verifyAuth, completeAuth, verificationCodeError }}
    >
      {children}
    </AuthContext.Provider>
  );
};
