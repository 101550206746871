import React, { useContext, useState, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Context
import { DataContext } from "context/DataContext";
import { AuthContext } from "context/AuthContext";

export const Step05 = () => {
  const { formData, setFormData, setStep, step, data } =
    useContext(DataContext);
  const { startAuth } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [transactionalEmailOptIn, setTransactionalEmailOptIn] = useState(false);
  const [marketingEmailOptIn, setMarketingEmailOptIn] = useState(false);

  useEffect(() => {
    if (formData?.email) {
      setEmail(formData?.email);
    }
  }, []);

  // Handlers
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      email,
      transactionalEmailOptIn,
      marketingEmailOptIn,
    });

    window.analytics.identify({"email": email, "transactionalEmailOptIn": transactionalEmailOptIn, "marketingEmailOptIn": marketingEmailOptIn });
    window.analytics.track("User Waitlisted", {...formData, email, transactionalEmailOptIn, marketingEmailOptIn})


    try {
      await startAuth({ userEmail: email });
      setStep(step + 1);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  return (
    <form className="w-full px-4 text-secondary" onSubmit={handleSubmit}>
      <div className="mb-6">
        <p className="text-4xl lg:text-5xl font-semibold font-gilroyBold">
          Almost done!
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          {data?.surveyCreateAccount}
        </p>
      </div>
      <div className="mt-[40px]">
        {/* Input Field: Email */}
        <label
          htmlFor="email"
          className="block font-gilroyMedium mb-2 font-medium text-secondary"
        >
          Email Address
        </label>

        <input
          type="email"
          id="email"
          className="h-[50px] bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
          required
          placeholder="name@example.com"
          value={email}
          onChange={handleEmailChange}
        />

        {/* Checkbox: Agree to Terms */}
        <div className="flex items-start mt-[34px]">
          <div className="flex items-center h-5">
            <input
              id="remember"
              type="checkbox"
              value=""
              className="h-[70px] capitalize bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
              required
            />
          </div>
          <label
            htmlFor="remember"
            className="ml-2 opacity-70 text-sm font-medium text-[#4B5563] "
          >
            By signing up, you are creating a Authentic account, and you agree
            to Authentic{" "}
            <a className="text-primary !opacity-100 cursor-pointer" href="_#">
              Terms of Use
            </a>{" "}
            and
            <a className="text-primary !opacity-100 cursor-pointer" href="_#">
              {" "}
              Privacy Policy
            </a>
            .
          </label>
        </div>

        {/* Checkbox: Transactional Email Opt In */}
        <div className="flex items-start mt-[34px]">
          <div className="flex items-center h-5">
            <input
              id="transactionalEmailOptIn"
              type="checkbox"
              value={transactionalEmailOptIn}
              onChange={(event) => {
                setTransactionalEmailOptIn(event.target.checked);
              }}
              className="h-[70px] capitalize bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
            />
          </div>
          <label
            htmlFor="transactionalEmailOptIn"
            className="ml-2 opacity-70 text-sm font-medium text-[#4B5563] "
          >
            Do you want to recieve transactional email updates?
          </label>
        </div>

        {/* Checkbox: Transactional Email Opt In */}
        <div className="flex items-start mt-[34px]">
          <div className="flex items-center h-5">
            <input
              id="marketingEmailOptIn"
              type="checkbox"
              value={marketingEmailOptIn}
              onChange={(event) => {
                setMarketingEmailOptIn(event.target.checked);
              }}
              className="h-[70px] capitalize bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
            />
          </div>
          <label
            htmlFor="marketingEmailOptIn"
            className="ml-2 opacity-70 text-sm font-medium text-[#4B5563] "
          >
            Do you want to recieve marketing email updates?
          </label>
        </div>
      </div>

      {/* Button: Create */}
      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
        onClick={() => window.analytics.track("Button Clicked", {"button_location": "onboarding_form", "intent": "onboarding", "title": "Create your account"})}
      >
        Create My Community
      </button>
    </form>
  );
};
