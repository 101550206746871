import React from "react";

// React Scroll Parallax
import { Parallax } from "react-scroll-parallax";

export const BeliefSection = () => {
  return (
    <>
      <div className="lg:hidden">
        <Parallax speed={-20}>
          <section
            id="belief"
            className="px-[24px] md:px-[48px] flex flex-col justify-center items-center mx-auto min-h-[600px] lg:min-h-[822px] w-full"
          >
            <div className="max-w-[1280px] z-20">
              <p
                data-aos="slide-down"
                data-aos-offset={"1000"}
                data-aos-duration={1000}
                className={`lg:text-[20px] leading-[120%] uppercase tracking-[0.1em] opacity-70 text-secondary dark:text-off-white font-proximaNovaSemiBold`}
              >
                Who we are
              </p>
              <h2
                data-aos="fade-up"
                data-aos-duration={1000}
                className={`font-gilroyBold text-6xl lg:text-[96px] tracking-[1.4px] leading-[100%] text-secondary dark:text-off-white lg:max-w-3xl max-w-lg mx-auto mt-6`}
              >
                People are better together
              </h2>
              <p
                data-aos="slide-up"
                data-aos-duration={1000}
                className={`font-proximaNovaRegular lg:text-[24px] leading-[120%] opacity-70 text-secondary dark:text-off-white max-w-sm mx-auto mt-6 lg:max-w-2xl`}
              >
                That’s why we built Authentic: to protect and enrich communities
                by restoring insurance to its first principles.
              </p>
            </div>
          </section>
        </Parallax>
      </div>
      <div className="hidden lg:block">
        <Parallax speed={-40}>
          <section
            id="belief"
            className="px-[24px] mt-[100px] flex flex-col justify-center items-center mx-auto min-h-[600px] lg:min-h-[822px] w-full"
          >
            <div className="max-w-[1280px] z-20">
              <p
                data-aos="slide-lef"
                data-aos-duration={1000}
                className={`lg:text-[20px] leading-[120%] uppercase tracking-[0.1em] opacity-70 text-secondary dark:text-off-white font-proximaNovaSemiBold`}
              >
                Who we are
              </p>
              <h2
                data-aos="fade-up"
                data-aos-duration={1000}
                className={`font-gilroyBold text-6xl lg:text-[96px] tracking-[1.4px] leading-[100%] text-secondary dark:text-off-white lg:max-w-4xl max-w-lg mx-auto mt-6`}
              >
                Captive insurance{" "}
                <span className="text-primary">infrastructure</span>
              </h2>
              <p
                data-aos="slide-up"
                data-aos-duration={1000}
                className={`font-proximaNovaRegular lg:text-[24px] leading-[120%] opacity-70 text-secondary dark:text-off-white max-w-sm mx-auto mt-6 lg:max-w-2xl`}
              >
                We create insurance companies for you.
              </p>
            </div>
          </section>
        </Parallax>
      </div>
    </>
  );
};
