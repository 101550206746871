import React from "react";

export const Heading2 = ({ text, className = "", onClick }) => {
  return (
    <h2
      onClick={onClick}
      className={`font-gilroyBold text-6xl lg:text-[96px] tracking-[1.4px] leading-[100%] ${className}`}
    >
      {text}
    </h2>
  );
};
