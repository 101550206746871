import React from "react";

export const PurposeSection = () => {
  return (
    <section
      id="purpose"
      className="px-[24px] md:px-[48px] -mt-[240px] flex flex-col justify-center items-center mx-auto min-h-[600px] lg:min-h-[822px] w-full"
    >
      <div className="max-w-[1280px] z-20">
        {/* <p
          data-aos="slide-down"
          data-aos-duration={1000}
          className={`lg:text-[20px] leading-[120%] uppercase tracking-[0.1em] opacity-70 text-secondary dark:text-off-white font-proximaNovaSemiBold`}
        >
          Who we serve:
        </p> */}
        <h3
          data-aos="fade-up"
          data-aos-duration={1000}
          className={`font-gilroyBold text-5xl lg:text-[64px] tracking-[1.4px] leading-[100%] max-w-lg  lg:max-w-4xl mx-auto mt-6`}
        >
          Who we serve
        </h3>
        <p
          data-aos="slide-up"
          data-aos-duration={1000}
          className={`font-proximaNovaRegular lg:text-[24px] leading-[120%] opacity-70 text-secondary dark:text-off-white max-w-sm mx-auto mt-6 lg:max-w-2xl`}
        >
          Associations, vertical software companies, franchises, and anyone with
          access to{" "}
          <span className="text-primary">small business communities.</span>
        </p>
      </div>
    </section>
  );
};
