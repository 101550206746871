import React, { useContext, useState, useEffect } from "react";

// Context
import { DataContext } from "context/DataContext";
import { AuthContext } from "context/AuthContext";

// Router
import { useNavigate } from "react-router-dom";

export const Step06 = () => {
  const { formData, setFormData } = useContext(DataContext);
  const [code, setCode] = useState("");


  const { verifyAuth, verificationCodeError } = useContext(AuthContext);

  useEffect(() => {
    if (formData?.code) {
      setCode(formData?.code);
    }
  }, []);

  // Handlers
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, code });
    localStorage.setItem("data", JSON.stringify(formData));

    await verifyAuth({ code, data: formData });
  };
  return (
    <form className="w-full px-4" onSubmit={handleSubmit}>
      <div className="mb-4">
        <p className="text-4xl lg:text-5xl opacity-100 font-semibold font-gilroyBold">
          Sit tight. You’ve got an email heading your way!
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          We’ve sent you a one time use code to the email address you provided
          to us. Please enter the code below to proceed.
        </p>
      </div>
      <div className="mt-[40px]">
        <label
          htmlFor="email"
          className="block font-gilroyMedium mb-2 font-medium text-secondary"
        >
          Verification Code
        </label>

        <input
          type="string"
          id="code"
          className="bg-beige lg:text-lg font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full h-[70px] px-6 "
          required
          value={code}
          onChange={handleCodeChange}
        />
        {verificationCodeError && verificationCodeError?.error_description && (
          <div className=" text-red-500 font-proximaNovaSemiBold mt-4">
            {verificationCodeError?.error_description}
          </div>
        )}
      </div>
      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
      >
        Continue To Account
      </button>
    </form>
  );
};
