import React, { useRef, useContext, useEffect, useState } from "react";

// Context
import { ModeContext } from "context";
import { DataContext } from "context/DataContext";

// Parts
import { Layout } from "parts";

// Copy To Clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaqCard } from "components";

export const ThankYou = () => {
  const { darkMode, setDarkMode } = useContext(ModeContext);
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState("https://authenticinsurance.com/");
 
  const formData = JSON.parse(localStorage.getItem("data")); 

  let locationRef = useRef();
  useEffect(() => {
    let location = window.location;
    if(locationRef.current === location.pathname){
        return;
    }
    window.analytics.page(location.pathname);
    locationRef.current = location.pathname;
  });

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center bg-off-white dark:bg-secondary text-secondary dark:text-off-white">
        {/* Theme  */}
        <div className="max-w-[1280px] w-full mx-auto flex z-20 justify-start items-start text-left mt-4 top-[108px] pl-[24px] lg:pl-[48px] xl:pl-0">
          <span
            onClick={() => setDarkMode(true)}
            className={`${
              darkMode ? "!font-proximaNovaBold" : "!font-proximaNovaMedium"
            } uppercase cursor-pointer hover:text-primary tracking-[28%] text-[12px] `}
          >
            Dark
          </span>{" "}
          <span className="mx-2 w-[1px] h-[20px] bg-secondary dark:bg-off-white"></span>
          <span
            onClick={() => setDarkMode(false)}
            className={`${
              darkMode ? "!font-proximaNovaMedium" : "!font-proximaNovaBold"
            } uppercase cursor-pointer hover:text-primary tracking-[28%] text-[12px] `}
          >
            Light
          </span>
        </div>

        {/* Hero */}
        <div className="max-w-5xl p-3 lg:p-2 mt-20 text-center mx-auto w-full font-proximaNovaRegular flex flex-col justify-center items-center text-secondary">
          <h2 className="text-secondary dark:text-off-white font-gilroyBold text-5xl lg:text-[64px] leading-[110%]">
            Thank you for creating <br />
            <span className="max-w-4xl w-full">
              the <span className="text-primary">{ formData.communityName }</span> Community{" "}
            </span>
          </h2>
          <p className="text-secondary dark:text-off-white opacity-70 mt-4 leading-[150%] lg:text-[24px] max-w-4xl">
            We have your community information and will reach back out with
            opportunities for coverage once we have products to offer you. In
            the mean time, feel free to contact us directly with any questions
            you may have by clicking the button below.
          </p>

          <a
            href="mailto:join@authenticins.com"
            className="relative inline-block uppercase font-proximaNovaBold tracking-[15%] text-[20px] group mt-8"
            onClick={() => window.analytics.track("Button Clicked", {"intent": "contact", "button_location": "thank_you_page", "title": "Contact Us Directly" })}
          >
            <span className="relative active:translate-x-1 active:translate-y-1 z-10 px-[38px] h-[64px] flex justify-center items-center overflow-hidden font-medium leading-tight text-primary transition-colors duration-300 ease-out border-2 border-primary rounded-lg">
              <span className="absolute inset-0 w-full h-full px-[38px] py-3 rounded-lg bg-off-white hover:bg-[#FFE6E3]"></span>
              <span className="relative ">Contact us Directly</span>
            </span>
            <span
              className="absolute bottom-0 right-0 w-full h-[64px] -mb-1 -mr-1 ease-linear bg-primary rounded-lg group-active:mt-0 group-active:ml-0"
              data-rounded="rounded-lg"
            >
              Contact us Directly
            </span>
          </a>
        </div>

        {/* Text */}
        <h2 className="font-gilroyBold mt-[125px] mb-[350px] text-4xl lg:text-[48px] text-center tracking-[1%] leading-[118%] max-w-2xl mx-auto">
          Your personalized coverage is on the horizon 😎
        </h2>

        {/* FAQ's */}
        <div className="text-secondary dark:text-off-white flex flex-col w-full">
          {/* <div className="border w-full h-[1px] text-gray-300 mt-4"></div> */}

          {/* Items */}
          <section
            id="cta"
            className="flex text-center flex-col relative justify-center items-center mx-auto bg-[#D9D1BF] min-h-[650px] lg:min-h-[822px] w-full"
          >
            <div className="max-w-[1280px] text-secondary px-[24px] md:px-[48px] z-20">
              {/* Items */}
              <div className="w-full grid gap-y-8 gap-x-8 4xl:gap-x-[42px] grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 content-center justify-items-center justify-center items-center -mt-[162px]">
                <FaqCard
                  title="Community-owned"
                  text="Authentic unlocks and simplifies the captive insurance
                    structure for all. Empowering communities of all kinds
                    to start their own insurance programs tailored to
                    their needs without any insurance complexity."
                />
                <FaqCard
                  title="Financial Benefits"
                  text="With better pricing and profit ownership, the financial benefits and decisions are self-directed and in the hands of the community itself."
                />
                <FaqCard
                  title="Turnkey Platform"
                  text="The Authentic captive insurance platform provides the entire infrastructure with little cost, time, and energy, enabling you to focus on building a better & more tailored product for your community."
                />
                <FaqCard
                  title="Trusted Members"
                  text="Insurance pools with the people you know and trust is core to keeping your community healthy and a key benefit of the authentic community-focused insurance program."
                />
              </div>

              <h2 className="text-secondary max-w-3xl mx-auto mt-[222px] font-gilroyBold text-5xl lg:text-[64px] leading-[110%]">
                Help us share <span className="text-primary">Authentic</span>{" "}
                with the world!
              </h2>
              <p className="font-proximaNovaMedium max-w-3xl text-secondary mx-auto mt-4 leading-[150%] lg:text-[24px]">
                If you’re into the things we are doing, why not help us spread
                the word by sharing a link with the people you trust and care
                about the most.
              </p>

              <div className="flex flex-col sm:mt-[50px] mb-[166px] justify-center items-center sm:flex-row w-full">
                {/* Input Field */}
                <div className="mt-[50px] sm:mr-[18px] sm:mt-0 relative max-w-[350px] w-full">
                  <input
                    type="text"
                    id="name"
                    className="h-[70px] pl-14 bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg block w-full px-6 "
                    required
                    readOnly
                    value={value}
                  />
                  <svg
                    className="absolute left-5 top-[23px]"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5" clipPath="url(#clip0_546_13377)">
                      <path
                        d="M5.33105 13.1475C5.8474 12.6311 6.3628 12.1138 6.88011 11.5984C7.23522 11.2453 7.73166 11.2302 8.06335 11.5609C8.39793 11.8936 8.38965 12.3919 8.03794 12.7446C7.08559 13.6998 6.12842 14.6502 5.178 15.6064C4.04587 16.7463 3.85606 18.3812 4.69112 19.7346C5.67007 21.3215 7.92005 21.7019 9.40922 20.5372C9.59311 20.3937 9.76399 20.2392 9.92767 20.0755C10.8328 19.1704 11.7369 18.2644 12.6429 17.3603C12.9975 17.0056 13.494 16.9925 13.8261 17.3237C14.1602 17.6559 14.1515 18.1547 13.7998 18.5074C12.7982 19.5108 11.801 20.5177 10.7918 21.5135C9.12983 23.1523 6.30967 23.3007 4.47487 21.9092C2.3701 20.313 1.90965 17.3555 3.40429 15.184C3.55033 14.9725 3.70601 14.7687 3.89062 14.586C4.37333 14.1091 4.85123 13.6273 5.33105 13.1475Z"
                        fill="#272B2E"
                      />
                      <path
                        d="M13.1584 5.32C13.6017 4.87671 14.0454 4.43294 14.4887 3.98965C16.2541 2.22817 18.984 2.02946 20.9509 3.51801C23.1125 5.15359 23.5032 8.20687 21.8393 10.4118C21.6778 10.6263 21.4927 10.8153 21.3051 11.0028C20.383 11.9279 19.4594 12.8505 18.5348 13.7731C18.1626 14.1453 17.672 14.1681 17.333 13.833C16.9826 13.4864 17.0043 12.9996 17.3892 12.6138C18.3249 11.6781 19.2617 10.7442 20.1955 9.80653C21.351 8.64622 21.5447 7.0093 20.6948 5.63616C19.7321 4.08211 17.4536 3.6956 16.0081 4.83584C15.4593 5.2691 15.0025 5.79624 14.5052 6.281C13.9149 6.85688 13.338 7.44622 12.7535 8.02884C12.396 8.38446 11.9034 8.40045 11.5698 8.06974C11.2251 7.72894 11.2396 7.2388 11.6088 6.86858C12.1242 6.35127 12.6415 5.83587 13.1579 5.31952L13.1584 5.32Z"
                        fill="#272B2E"
                      />
                      <path
                        d="M13.2759 13.2673C11.9221 14.6211 10.5689 15.9753 9.21419 17.328C8.93457 17.6076 8.57925 17.6703 8.25187 17.5114C7.92981 17.355 7.75834 17.0354 7.80358 16.6725C7.83154 16.4491 7.95505 16.2813 8.11142 16.1249C10.5168 13.7224 12.9203 11.3179 15.3243 8.91395C15.6034 8.63482 15.8821 8.35425 16.1622 8.07609C16.5275 7.71461 17.0404 7.69754 17.3696 8.03456C17.6917 8.36339 17.6732 8.86809 17.318 9.22417C15.9716 10.5735 14.6233 11.9199 13.2749 13.2673L13.2759 13.2673Z"
                        fill="#272B2E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_546_13377">
                        <rect
                          width="24.7488"
                          height="9.78442"
                          fill="white"
                          transform="translate(0.488281 17.9883) rotate(-45)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                {/* Copy Button */}
                <CopyToClipboard text={value}
                    onClick={() => window.analytics.track("Button Clicked", {"intent": "share", "button_location": "thank_you_page", "title": "Copy" })}
                    onCopy={() => setCopied(true)}>
                  <button className="relative inline-block uppercase font-proximaNovaBold tracking-[15%] text-[20px] group mt-8 sm:mt-0">
                    <span className="relative active:translate-x-1 active:translate-y-1 z-10 px-[38px] h-[64px] flex justify-center items-center overflow-hidden font-medium leading-tight text-secondary transition-colors duration-300 ease-out border-2 border-secondary rounded-lg">
                      <span className="absolute inset-0 w-full h-full px-[38px] py-3 rounded-lg bg-off-white hover:bg-[#FFE6E3]"></span>
                      <span className="relative ">
                        {" "}
                        {copied ? (
                          <span className="text-primary normal-case">
                            Copied !
                          </span>
                        ) : (
                          "Copy Link"
                        )}
                      </span>
                    </span>

                    <span
                      className="absolute bottom-0 right-0 w-full h-[64px] -mb-1 -mr-1 ease-linear bg-secondary rounded-lg group-active:mt-0 group-active:ml-0"
                      data-rounded="rounded-lg"
                    >
                      {copied ? (
                        <span className="text-primary normal-case">
                          Copied !
                        </span>
                      ) : (
                        "Copy Link"
                      )}
                    </span>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </section>

          {/* <div className="mt-[140px] lg:flex flex-col">
            <div className="lg:flex lg:justify-between">
              {data?.roadmapItems && data?.roadmapItems[0] && (
                <div className="mb-8 lg:w-1/2 flex items-start justify-start max-w-lg">
                  <div className="rounded-full mr-2 mt-1 bg-primary  inline-flex justify-center items-center w-4 h-4 text-sm font-medium text-off-white drop-shadow-lg font-gilroyBold">
                    ?
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-semibold font-gilroyBold">
                      {data?.roadmapItems[0]?.name}
                    </p>
                    <p className="font-light mt-2 font-proximaNovaRegular opacity-80 text-sm">
                      {data?.roadmapItems[0]?.description}
                    </p>
                  </div>
                </div>
              )}
              {data?.roadmapItems && data?.roadmapItems[1] && (
                <div className="mb-8 lg:w-1/2 flex items-start justify-start max-w-lg">
                  <div className="rounded-full mr-2 mt-1 bg-primary  inline-flex justify-center items-center w-4 h-4 text-sm font-medium text-off-white drop-shadow-lg font-gilroyBold">
                    ?
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-semibold font-gilroyBold">
                      {data?.roadmapItems[1]?.name}
                    </p>
                    <p className="font-light mt-2 font-proximaNovaRegular opacity-80 text-sm">
                      {data?.roadmapItems[1]?.description}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="lg:flex lg:justify-between">
              {data?.roadmapItems && data?.roadmapItems[2] && (
                <div className="mb-8 lg:w-1/2 flex items-start justify-start max-w-lg">
                  <div className="rounded-full mr-2 mt-1 bg-primary  inline-flex justify-center items-center w-4 h-4 text-sm font-medium text-off-white drop-shadow-lg font-gilroyBold">
                    ?
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-semibold font-gilroyBold">
                      {data?.roadmapItems[2]?.name}
                    </p>
                    <p className="font-light mt-2 font-proximaNovaRegular opacity-80 text-sm">
                      {data?.roadmapItems[2]?.description}
                    </p>
                  </div>
                </div>
              )}
              {data?.roadmapItems && data?.roadmapItems[3] && (
                <div className="mb-8 lg:w-1/2 flex items-start justify-start max-w-lg">
                  <div className="rounded-full mr-2 mt-1 bg-primary  inline-flex justify-center items-center w-4 h-4 text-sm font-medium text-off-white drop-shadow-lg font-gilroyBold">
                    ?
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-semibold font-gilroyBold">
                      {data?.roadmapItems[3]?.name}
                    </p>
                    <p className="font-light mt-2 font-proximaNovaRegular opacity-80 text-sm">
                      {data?.roadmapItems[3]?.description}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};
