import React, { useContext, useEffect } from "react";

// Parts
import { Footer, Nav } from "parts";

// Context
import { ModeContext } from "context";

export const Layout = ({ children }) => {
  const { darkMode } = useContext(ModeContext);

  useEffect(() => {
    if (darkMode) {
      document.getElementById("root").classList.add("dark");
    } else {
      document.getElementById("root").classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div className={`flex flex-col justify-between min-h-screen w-full`}>
      <Nav />
      {children}
      <Footer />
    </div>
  );
};
