import React, { useState, useContext, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Context
import { DataContext } from "context/DataContext";

export const Step02 = () => {
  const { formData, setFormData, setStep, step, data } =
    useContext(DataContext);
  const [communityName, setCommunityName] = useState("");

  useEffect(() => {
    if (formData?.communityName !== "") {
      setCommunityName(formData?.communityName);
    }
  }, []);

  // Handlers
  const handleCommunityNameChange = (event) => {
    setCommunityName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({ ...formData, communityName });
    window.analytics.identify({"communityName": communityName});
    setStep(step + 1);
  };

  return (
    <form className="w-full px-4 text-secondary" onSubmit={handleSubmit}>
      <div className="mb-4">
        <p className="text-4xl lg:text-5xl font-semibold font-gilroyBold">
          Awesome, {formData?.name?.split(" ")?.[0]}
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          So you’re here to build a community? What should we call your
          business, group, or organization?
        </p>
      </div>
      <div className="mt-[40px]">
        <input
          type="text"
          id="communityName"
          className="h-[70px] bg-beige text-[16px] font-proximaNovaRegular placeholder:font-proximaNovaRegular text-[#44484C] placeholder:text-[#44484c] placeholder:opacity-50 border border-[#D9D1BF/50] rounded-lg focus:ring-primary focus:border-primary block w-full px-6 "
          required
          placeholder="What is the name of your community?"
          value={communityName}
          onChange={handleCommunityNameChange}
        />
      </div>

      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
        onClick={() => window.analytics.track("Button Clicked", {"button_location": "onboarding_form", "intent": "onboarding", "title": "Next: Community Size"})}
      >
        <strong className="font-proximaNovaBold">Next:</strong> Community Size
      </button>
    </form>
  );
};
