import { useState, useEffect, createContext } from "react";

// Sanity
import sanityClient from "clients/sanityClient";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    communityName: "",
    communitySize: "",
    insuranceType: "",
    email: "",
    code: "",
    transactionalEmailOptIn: false,
    marketingEmailOptIn: false,
  });

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "client"]{
          id,
          name,
          logoImage{
            'url': asset->url
          },
          logoText,
          headline,
          subHeading,
          heroImage{
            'url': asset->url
          },
          videoUrl,
          roadmapHeading,
          roadmapText, 
          'roadmapItems': roadmapItems[]->{
              name,
              description
            },
          linkText,
          linkButtonText,
          promotionHeadline,
          promotionSubHeading,
          promotionImage{
            'url': asset->url
          },
          communityHeadline,
          communitySubHeading,
          footerText,
          github,
          facebook,
          dribble,
          twitter,
          surveyName,
          surveyCommunityName,
          surveyCommunitySize,
          surveyCoverage,
          surveyCreateAccount
    }
  `
      )
      .then((resp) => {
        if (resp[0]) {
          // Filter Client
          // TODO: Move this id to env
          const id = "authentic-fake-door";

          const filteredClient = resp?.filter((item) => item?.id === id);
          if (filteredClient[0]) {
            setData(filteredClient[0]);
          } else {
            console.log("Client Not Found !");
          }
        }
      })
      .catch(console.error);
  }, []);

  return (
    <DataContext.Provider
      value={{ data, step, setStep, formData, setFormData }}
    >
      {children}
    </DataContext.Provider>
  );
};
