import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { DataProvider, AuthProvider, ModeProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DataProvider>
    <AuthProvider>
      <ModeProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ModeProvider>
    </AuthProvider>
  </DataProvider>
);
