import React from "react";

export const Heading1 = ({ text, className = "", onClick }) => {
  return (
    <h1
      onClick={onClick}
      className={`font-gilroyBold text-7xl lg:text-[136px] tracking-[2px] leading-[100%] ${className}`}
    >
      {text}
    </h1>
  );
};
