import React, { useRef, useEffect, useContext } from "react";

// Context
import { AuthContext } from "context/AuthContext";

// Router
import { useNavigate } from "react-router-dom";

export const Verified = () => {
  const { completeAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const data = JSON.parse(localStorage.getItem("data"));


  let locationRef = useRef();
  useEffect(() => {
    let location = window.location;
    if(locationRef.current === location.pathname){
        return;
    }
    window.analytics.page(location.pathname);
    locationRef.current = location.pathname;
  });

  // Handlers
  const submitForm = async ({
    lobbyOwner,
    communitySize,
    status,
    insuranceType,
    ownerEmail,
    transactionalEmailOptIn,
    marketingEmailOptIn,
  }) => {
    let body = {
      lobbyOwner,
      communitySize,
      insuranceType,
      ownerEmail,
      transactionalEmailOptIn,
      marketingEmailOptIn,
    };



    try {
      // Create Lobby
      const resp = await fetch(`${baseUrl}/lobby`, {
        method: "POST",
        body: JSON.stringify(body),
      });



      if (resp?.status === 200) {
        navigate("/thank-you");
      }
    } catch (error) {
      console.log("ERROR :: ", error);
    }
  };

  useEffect(() => {
    //completeAuth();


    // // TODO: Grab formData from data field in the URL

    // TODO: Do API request to create Lobby (Form API Implementation)
    console.log({
      lobbyOwner: data?.name,
      communitySize: data?.communitySize,
      insuranceType: data?.insuranceType,
      ownerEmail: data?.email,
      status: "WAITLIST", // ? Where do we get the status from ?
      transactionalEmailOptIn: data?.transactionalEmailOptIn,
      marketingEmailOptIn: data?.marketingEmailOptIn,

    });

    submitForm({
      lobbyOwner: data?.name,
      communitySize: data?.communitySize,
      insuranceType: data?.insuranceType,
      ownerEmail: data?.email,
      status: "WAITLIST",
      transactionalEmailOptIn: data?.transactionalEmailOptIn,
      marketingEmailOptIn: data?.marketingEmailOptIn,

    });
    
  });

  return (
    <div className="mx-auto w-full min-h-screen flex">
      <div className="max-w-xl md:max-w-[700px] lg:max-w-[800px] mx-auto w-full min-h-screen font-proximaNovaRegular flex flex-col justify-center items-center text-secondary md:w-2/3"></div>
    </div>
  );
};
