import React, { useState, useContext, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Context
import { DataContext } from "context/DataContext";

export const Step03 = () => {
  const { formData, setFormData, setStep, step, data } =
    useContext(DataContext);
  const [communitySize, setCommunitySize] = useState("");

  useEffect(() => {
    if (formData?.communitySize) {
      setCommunitySize(formData?.communitySize);
    }
  }, []);

  // Handlers
  const handleCommunitySizeChange = (event) => {
    setCommunitySize(event.target.name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.analytics.identify({"communitySize": communitySize});
    setFormData({ ...formData, communitySize });
    setStep(step + 1);
  };

  return (
    <form className="w-full px-4 text-secondary" onSubmit={handleSubmit}>
      <div className="mb-4">
        <p className="text-4xl lg:text-5xl font-semibold font-gilroyBold">
          {formData?.communityName} it is 😀
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          How many people in the {formData?.communityName} Community?
        </p>
      </div>
      <div className="mt-[40px]">
        <ul className="mt-2 text-sm font-medium text-center text-secondary rounded-lg divide-x divide-[#D9D1BF/30] shadow sm:flex">
          <li className="w-full">
            <button
              onClick={handleCommunitySizeChange}
              type="button"
              name="0-1K"
              className={`inline-block h-[70px] px-5 w-full ${
                communitySize === "0-1K"
                  ? "text-off-white bg-secondary"
                  : "text-secondary bg-beige "
              }  font-proximaNovaRegular rounded-l-lg`}
            >
              1 - 1,000
            </button>
          </li>
          <li className="w-full">
            <button
              onClick={handleCommunitySizeChange}
              type="button"
              name="1K-10K"
              className={`inline-block h-[70px] px-5 w-full ${
                communitySize === "1K-10K"
                  ? "text-off-white bg-secondary"
                  : "text-secondary bg-beige "
              }  font-proximaNovaRegular`}
            >
              1,000 - 10,000
            </button>
          </li>
          <li className="w-full">
            <button
              onClick={handleCommunitySizeChange}
              type="button"
              name="10K-100K"
              className={`inline-block h-[70px] px-5 w-full ${
                communitySize === "10K-100K"
                  ? "text-off-white bg-secondary"
                  : "text-secondary bg-beige "
              }  font-proximaNovaRegular`}
            >
              10,000 - 100,000
            </button>
          </li>
          <li className="w-full">
            <button
              onClick={handleCommunitySizeChange}
              type="button"
              name="100K+"
              className={`inline-block h-[70px] px-5 w-full ${
                communitySize === "100K+"
                  ? "text-off-white bg-secondary"
                  : "text-secondary bg-beige "
              }  font-proximaNovaRegular rounded-r-lg`}
            >
              100,000+
            </button>
          </li>
        </ul>
      </div>

      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
        onClick={() => window.analytics.track("Button Clicked", {"button_location": "onboarding_form", "intent": "onboarding", "title": "Next: Coverage Type"})}
      >
        <strong className="font-proximaNovaBold">Next:</strong> Coverage Type
      </button>
    </form>
  );
};
