import React, { useContext, useState, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Context
import { DataContext } from "context/DataContext";

export const Step04 = () => {
  const { formData, setFormData, setStep, step, data } =
    useContext(DataContext);
  const [insuranceType, setInsuranceType] = useState("");

  useEffect(() => {
    if (formData?.insuranceType) {
      setInsuranceType(formData?.insuranceType);
    }
  }, []);

  // Handlers
  const handleInsuranceTypeChange = (event) => {
    setInsuranceType(event.target.name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({ ...formData, insuranceType });
    window.analytics.identify({"insuranceType":insuranceType });
    setStep(step + 1);
  };

  return (
    <form
      className="w-full px-4 xl:px-0 text-secondary"
      onSubmit={handleSubmit}
    >
      <div className="mb-4">
        <p className="text-4xl lg:text-5xl font-semibold font-gilroyBold">
          Perfect, you're on a roll!
        </p>
        <p className="mt-4 font-light text-lg lg:text-2xl opacity-80 font-proximaNovaRegular">
          {data?.surveyCoverage}
        </p>
      </div>
      <div className="mt-[40px] grid grid-cols-1 grid-rows-3 lg:grid-cols-3  lg:grid-rows-1 gap-2">
        <button
          type="button"
          name="personal"
          className={`inline-block px-5 h-[70px] rounded-lg  col-span-1 ${
            insuranceType === "personal"
              ? "text-off-white bg-secondary font-proximaNovaBold"
              : "text-secondary bg-beige "
          } `}
          onClick={handleInsuranceTypeChange}
        >
          Personal
        </button>

        <button
          type="button"
          name="business"
          className={`inline-block px-5 h-[70px] rounded-lg col-span-1 ${
            insuranceType === "business"
              ? "text-off-white bg-secondary font-proximaNovaBold"
              : "text-secondary bg-beige "
          } `}
          onClick={handleInsuranceTypeChange}
        >
          Business
        </button>

        <button
          type="button"
          name="both"
          className={`inline-block px-5 h-[70px] rounded-lg   col-span-1 ${
            insuranceType === "both"
              ? "text-off-white bg-secondary font-proximaNovaBold"
              : "text-secondary bg-beige "
          } `}
          onClick={handleInsuranceTypeChange}
        >
          Both
        </button>
      </div>

      <button
        type="submit"
        className="font-proximaNovaMedium mt-[46px] text-white bg-primary hover:bg-[#DD3923] focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-lg lg:text-xl w-full px-5 h-[70px] text-center"
        onClick={() => window.analytics.track("Button Clicked", {"button_location": "onboarding_form", "intent": "onboarding", "title": "Next: Create your account"})}
      >
        <strong className="font-proximaNovaBold">Next:</strong> Create your
        account
      </button>
    </form>
  );
};
