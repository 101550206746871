import React, { useRef, useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
// Parts
import { Layout } from "parts";

// Context
import { DataContext } from "context";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Components
import {
  HeroSection,
  BeliefSection,
  EffectSection,
  PurposeSection,
  CtaSection,
} from "components";

export const Root = () => {
  const { setFormData, setStep } = useContext(DataContext);
  
  let locationref = useRef();
  useEffect(() => {
    let location = window.location;
    if(locationref.current === location.pathname){
        return;
    }
    window.analytics.page(location.pathname);
    locationref.current = location.pathname;
  });



  useEffect(() => {
    setFormData({
      name: "",
      communityName: "",
      communitySize: "",
      insuranceType: "",
      email: "",
      code: "",
    });

    setStep(1);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <Layout>
      <div className="flex flex-col relative items-center justify-evenly text-center font-gilroySemiBold text-secondary dark:text-off-white bg-off-white dark:bg-secondary overflow-x-hidden">
        {/* Hero Section */}
        <HeroSection />

        {/* Belief Section */}
        <BeliefSection />

        {/* Effect Section */}
        <EffectSection />

        {/* Purpose Section */}
        <PurposeSection />

        {/* CTA Section */}
        <CtaSection />

        {/* Navigation Buttons */}
        {/* <div
          style={{
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
          className="flex pl-[15px] 2xl:pl-[4px] overflow-hidden z-10 fixed top-0 left-0 w-full max-w-[1328px] flex-col justify-center items-start min-h-screen scroll-smooth"
        >
          <Link
            hashSpy={true}
            spy={true}
            to="hero"
            className="w-[8px] h-[8px] rounded-full cursor-pointer relative"
            activeClass="bg-primary"
            offset={-120}
          >
            <div className="absolute -left-[3px] -top-[3px]  border border-secondary dark:border-off-white rounded-full w-[14px] h-[14px]"></div>
          </Link>

          <Link
            hashSpy={true}
            spy={true}
            to="belief"
            className="w-[8px] h-[8px] rounded-full cursor-pointer relative mt-[14px]"
            activeClass="bg-primary"
          >
            <div className="absolute -left-[3px] -top-[3px]  border border-secondary dark:border-off-white rounded-full w-[14px] h-[14px]"></div>
          </Link>

          <Link
            hashSpy={true}
            spy={true}
            to="effect"
            className="w-[8px] h-[8px] rounded-full cursor-pointer relative mt-[14px]"
            activeClass="bg-primary"
          >
            <div className="absolute -left-[3px] -top-[3px]  border border-secondary dark:border-off-white rounded-full w-[14px] h-[14px]"></div>
          </Link>

          <Link
            hashSpy={true}
            spy={true}
            to="purpose"
            className="w-[8px] h-[8px] rounded-full cursor-pointer relative mt-[14px]"
            activeClass="bg-primary"
          >
            <div className="absolute -left-[3px] -top-[3px]  border border-secondary dark:border-off-white rounded-full w-[14px] h-[14px]"></div>
          </Link>

          <Link
            hashSpy={true}
            spy={true}
            to="cta"
            className="w-[8px] h-[8px] rounded-full cursor-pointer relative mt-[14px]"
            activeClass="bg-primary"
          >
            <div className="absolute -left-[3px] -top-[3px]  border border-secondary dark:border-off-white rounded-full w-[14px] h-[14px]"></div>
          </Link>
        </div> */}
      </div>
    </Layout>
  );
};
