import React from "react";

export const SubHeading2 = ({ text, className = "", onClick }) => {
  return (
    <h4
      onClick={onClick}
      className={`font-proximaNovaRegular lg:text-[32px] leading-[120%] ${className}`}
    >
      {text}
    </h4>
  );
};
