import React from "react";

export const FaqCard = ({ title, text }) => {
  return (
    <div className="relative max-w-[284px] inline-block font-proximaNovaBold tracking-[15%] text-[20px]">
      <span className="relative border-2 border-secondary rounded-3xl z-10 h-[282px] w-full max-w-[284px] flex justify-center items-center overflow-hidden font-medium leading-tight text-secondary transition-colors duration-300 ease-out ">
        <span className="absolute inset-0 w-full h-full rounded-lg bg-secondary dark:bg-primary"></span>
        <div className="relative w-full flex flex-col justify-start items-start">
          <div className="flex justify-center items-center font-gilroyMedium text-[24px] tracking-[1.4px] leading-[114.5%] bg-secondary dark:bg-primary  text-off-white h-[70px] w-full rounded-t-3xl">
            <p className="w-full">{title}</p>
          </div>
          <div className="px-[25px] bg-off-white h-[212px]">
            <p className="text-[14px] max-w-[233px] text-left mt-[19px] text-secondary tracking-[1%] leading-[23.36px] opacity-80 font-proximaNovaRegular">
              {text}
            </p>
          </div>
        </div>
      </span>

      <span
        className="absolute bottom-0 right-0 w-full h-[282px] max-w-[284px] -mb-[8px] -mr-[8px] ease-linear bg-[#D9D1BF] border-2 border-secondary rounded-3xl group-active:mt-0 group-active:ml-0"
        data-rounded="rounded-lg"
      ></span>
    </div>
  );
};
